$color-mode-type: media-query;

@import "/node_modules/bootstrap/scss/bootstrap";
@import "/node_modules/bootstrap-icons/font/bootstrap-icons.min.css";

@include color-mode(light) {
	.dark-only {
		display: none;
	}
}

@include color-mode(dark) {
	.light-only {
		display: none;
	}
}

i {
	width: 1em;
}

.cow {
	transition: transform .5s;
}

.rotated {
	transform: rotate(360deg);
}


.hamburger.burger-activated {
	background-image: url("/src/assets/hamburger.png?width=64");
}

.text-balancewrap {
	// balance is a relatively new feature, there's no real negative repercussion to it not existing other than the page looking a little uglier
	text-wrap: balance;
}